var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "onlinestore-point" }, [
    _c("div", { staticClass: "c-section c-section--fluid" }, [
      _c(
        "div",
        { staticClass: "c-section__inner" },
        [
          _c("Card", { staticClass: "c-card--pa16", attrs: { radius: "8" } }, [
            _c(
              "div",
              { staticClass: "c-card__content" },
              [
                _vm.isOnlineStoreUser
                  ? [
                      _c("p", { staticClass: "c-title6" }, [
                        _vm._v(_vm._s(_vm.WORDS.ONLINESTORE_POINT)),
                      ]),
                      _c("div", { staticClass: "onlinestore-point-status" }, [
                        _c(
                          "a",
                          {
                            staticClass: "onlinestore-point-history-button",
                            attrs: {
                              href: _vm.$customUrlScheme.page(
                                "ec",
                                "url=" +
                                  _vm.ONLINESTORE_DOMAIN +
                                  "/member_point_record.html"
                              ),
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.WORDS.MILE_PROGRESS.POINT_HISTORY)
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "onlinestore-point-status__number" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.onlineStorePointValue) + " "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  : [
                      _c(
                        "p",
                        { staticClass: "c-title6" },
                        [
                          _c("Icon", { attrs: { name: "cartOff" } }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.WORDS.OFFICIAL) +
                              _vm._s(_vm.WORDS.ONLINESTORE) +
                              "会員 "
                          ),
                        ],
                        1
                      ),
                      _c("p", { staticClass: "c-lead c-lead--grey01" }, [
                        _vm._v(
                          " ご登録いただくと" +
                            _vm._s(_vm.WORDS.ONLINESTORE) +
                            "で使える" +
                            _vm._s(_vm.WORDS.POINT) +
                            "をゲットすることができます。 "
                        ),
                      ]),
                      _c(
                        "Button",
                        {
                          attrs: {
                            href: _vm.$customUrlScheme.page("ec"),
                            "style-type": "secondary",
                            fluid: "",
                            squared: "",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.WORDS.ACCOUNT_CREATE) + "する "
                          ),
                        ]
                      ),
                      _c(
                        "Button",
                        {
                          attrs: {
                            href: _vm.$customUrlScheme.page(
                              "standalone",
                              "url=/link/onlinestore"
                            ),
                            "style-type": "quaternary",
                            fluid: "",
                            squared: "",
                            bordered: "",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.WORDS.ACCOUNT_LOGIN) + "する "
                          ),
                        ]
                      ),
                    ],
              ],
              2
            ),
            _vm.isOnlineStoreUser
              ? _c("div", { staticClass: "c-card__bottomTextButton" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "c-textButton c-textButton--sm c-textButton--center",
                      attrs: { href: _vm.$customUrlScheme.page("ec") },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.WORDS.OFFICIAL) +
                          _vm._s(_vm.WORDS.ONLINESTORE) +
                          "を見る "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }