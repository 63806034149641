<template>
  <div class="onlinestore-point">
    <div class="c-section c-section--fluid">
      <div class="c-section__inner">
        <Card class="c-card--pa16" radius="8">
          <div class="c-card__content">
            <template v-if="isOnlineStoreUser">
              <p class="c-title6">{{ WORDS.ONLINESTORE_POINT }}</p>
              <div class="onlinestore-point-status">
                <a
                  :href="
                    $customUrlScheme.page(
                      'ec',
                      `url=${ONLINESTORE_DOMAIN}/member_point_record.html`
                    )
                  "
                  class="onlinestore-point-history-button">{{ WORDS.MILE_PROGRESS.POINT_HISTORY }}</a>
                <div class="onlinestore-point-status__number">
                  {{ onlineStorePointValue }}
                </div>
              </div>
            </template>

            <template v-else>
              <p class="c-title6">
                <Icon name="cartOff" />
                {{ WORDS.OFFICIAL }}{{ WORDS.ONLINESTORE }}会員
              </p>
              <p class="c-lead c-lead--grey01">
                ご登録いただくと{{ WORDS.ONLINESTORE }}で使える{{
                  WORDS.POINT
                }}をゲットすることができます。
              </p>
              <Button
                :href="$customUrlScheme.page('ec')"
                style-type="secondary"
                fluid
                squared>
                {{ WORDS.ACCOUNT_CREATE }}する
              </Button>
              <Button
                :href="
                  $customUrlScheme.page('standalone', 'url=/link/onlinestore')
                "
                style-type="quaternary"
                fluid
                squared
                bordered>
                {{ WORDS.ACCOUNT_LOGIN }}する
              </Button>
            </template>
          </div>

          <div v-if="isOnlineStoreUser" class="c-card__bottomTextButton">
            <a
              :href="$customUrlScheme.page('ec')"
              class="c-textButton c-textButton--sm c-textButton--center">
              {{ WORDS.OFFICIAL }}{{ WORDS.ONLINESTORE }}を見る
            </a>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, ref, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    /**
     * オンラインストアの連携ステータス
     */
    isOnlineStoreUser: {
      type: Boolean,
      default: false
    }
  },

  setup: (props, context) => {
    const ONLINESTORE_DOMAIN = process.env.VUE_APP_EC_BASE_URL;
    const ecCustomerData = ref(undefined);

    /**
     * ユーザのオンラインストア情報を取得
     */
    const fetchEcCustomerData = async () => {
      try {
        context.root.$_loading_start();
        const { data } = await context.root
          .$repositories('customer')
          .getEcCustomer();
        ecCustomerData.value = data;
      } catch {
        // noop
      } finally {
        context.root.$_loading_stop();
      }
    };

    /**
     * オンラインストアポイント
     * @returns {number}
     */
    const onlineStorePointValue = computed(
      () => ecCustomerData.value?.['point'] || 0
    );

    /**
     * オンラインストアステージ
     * @returns {number}
     */
    const onlineStoreStage = computed(
      () => ecCustomerData.value?.['rank'] || 0
    );

    fetchEcCustomerData();

    return {
      WORDS,
      ONLINESTORE_DOMAIN,
      ecCustomerData,
      onlineStorePointValue,
      onlineStoreStage
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.onlinestore-point {
  .c-card__content {
    padding-right: 4px;
    padding-left: 4px;
    :last-child {
      margin-bottom: 0;
    }
  }
}

.onlinestore-point-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  line-height: 1;
}

.onlinestore-point-status__number {
  flex-grow: 1;
  color: variables.$secondary01;
  font-size: 32px;
  font-weight: bold;
  text-align: right;
}

.onlinestore-point-history-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 13px;
  background-color: variables.$grey04;
  border-radius: 4px;
  color: variables.$black01;
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
}
</style>
